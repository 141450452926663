import * as React from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { Loading } from '@shared/components/loading'
import { changeDocumentHash } from '@shared/pipes/dom'
import scrollToElement from '@shared/pipes/scroll'
import { AnimateSharedLayout, motion } from 'framer-motion'

import { Layout } from '../components/Layout'

import { useFormCookie, useSessionData, useSteps } from './hooks'
import { EsiaAuth, Primary } from './steps'
import { FormProps, StepKeys } from './types'
export interface ApplicationFormContextDto {
	isSessionActive: boolean
	stepController: ReturnType<typeof useSteps>
	cookieController: ReturnType<typeof useFormCookie>
	formController: ReturnType<typeof useSessionData>['form']
}

const stepsComponents: Record<StepKeys, (props: FormProps) => JSX.Element> = {
	primary: Primary,
	'esia-auth': EsiaAuth,
}

export const ApplicationFormContext = React.createContext<ApplicationFormContextDto>(null!)

export const ApplicationForm = (props: FormProps) => {
	const session = useSessionData({ minLoan: props.minLoan! })
	const steps = useSteps(session.forcedStep!)
	const cookies = useFormCookie(steps.current, session.active!)

	const Step = stepsComponents[steps.current] || Loading

	const contextValue: ApplicationFormContextDto = {
		isSessionActive: session.active!,
		cookieController: cookies,
		formController: session.form,
		stepController: steps,
	}

	const motionProps: React.ComponentProps<typeof motion.div> = {
		key: steps.current,
		initial: { opacity: 0 },
		animate: { opacity: 1 },
	}

	return (
		<div id={AnchorsOfForms.application}>
			<ApplicationFormContext.Provider value={contextValue}>
				<Layout>
					<AnimateSharedLayout>
						<motion.div {...motionProps}>
							<Step {...props} />
						</motion.div>
					</AnimateSharedLayout>
				</Layout>
			</ApplicationFormContext.Provider>
		</div>
	)
}

export const scrollToApplicationForm = () => {
	scrollToElement(`#${AnchorsOfForms.application}`, { time: 0 }, () => {
		changeDocumentHash(AnchorsOfForms.application)
	})
}
